import React from 'react';
import PropTypes from 'prop-types';
import radioButtonStyles from './radio-button.module.scss';

const RadioButton = ({
  name, value, checked, color, className, children,
}) => (
  <label
    htmlFor={value}
    title={value}
    className={`${className} button ${radioButtonStyles.radioButton} ${color ? radioButtonStyles.color : ''} ${color && checked ? radioButtonStyles.active : ''}`}
    style={{ backgroundColor: color || 'transparent' }}
  >
    <div>{children}</div>
    <input type="radio" name={name} id={value} value={value} checked={checked} onChange={() => {}} />
  </label>

);

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
};

export default RadioButton;
