import { Link, navigate } from 'gatsby';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import JSONData from '../../conf/label-maker.json';
import { setLabel as setLabelAction } from '../../state/actions';
import { getLabel } from '../../state/selectors';
import { defaultLabel } from '../../utils/js/default-label';
import { getValueFromObject } from '../../utils/js/get-value-from-object';
import { localizedUrl } from '../../utils/js/locale-utils';
import { getLabelFromLocalstorage, saveLabelToLocalstorage } from '../../utils/js/localstorage';
import '../../utils/sass/maker-fonts.scss';
import LabelMakerComponents from './label-maker-components';
import labelMakerStyles from './label-maker.module.scss';

const LabelMaker = ({
  intl, label, setLabel, children, category, categorySlug, location,
}) => {
  // TODO: Make this hash translatable
  const LABEL_MAKER_BODY_HASH = 'label-maker-body';
  const lang = process.env.GATSBY_LANG || 'en';

  let currentStep;
  function loadStep() {
    const step = JSONData.steps.find(
      (s) => location.pathname.indexOf(s.locale[lang] || s.id) > -1,
    );

    return step || JSONData.steps[0];
  }
  currentStep = loadStep();

  function nextStep() {
    currentStep = JSONData.steps[currentStep.order < JSONData.steps.length - 1
      ? currentStep.order + 1 : JSONData.steps.length - 1];
    navigate(`${localizedUrl(`${categorySlug}/${currentStep.id}`)}#${LABEL_MAKER_BODY_HASH}`);
  }

  function previousStep() {
    currentStep = JSONData.steps[currentStep.order > 0 ? currentStep.order - 1 : 0];
    navigate(`${localizedUrl(`${categorySlug}/${currentStep.id}`)}#${LABEL_MAKER_BODY_HASH}`);
  }

  if (typeof window !== 'undefined') {
    // Save state to localstorage before refresh/close
    window.onbeforeunload = () => {
      saveLabelToLocalstorage({ label }, category);
    };

    // Hide hash from URL
    window.history.replaceState('', document.title, window.location.pathname + window.location.search);
  }

  const PreviewShape = LabelMakerComponents.shape[label.shape];

  const tabs = [];
  JSONData.steps.map((tab) => tabs.push(
    <li className={`steps-segment ${currentStep.id === tab.id ? 'is-active' : ''}`} key={tab.id}>
      <Link to={`${localizedUrl(`${categorySlug}/${tab.id}`)}#${LABEL_MAKER_BODY_HASH}`} className="has-text-dark">
        <span className="steps-marker">
          <span className="icon">
            <i className={tab.icon} />
          </span>
        </span>
        <div className="steps-content">
          <p className="heading">
            <FormattedMessage id={`label-maker.steps.${tab.id}`} />
          </p>
        </div>
      </Link>
    </li>,
  ));

  const imageSrc = LabelMakerComponents.image[label.image];
  const imageStyle = {
    top: `${getValueFromObject(JSONData, `labels.${label.shape}.override.image.top`) || 140}px`,
    left: `${getValueFromObject(JSONData, `labels.${label.shape}.override.image.left`) || 42}px`,
    width: `${getValueFromObject(JSONData, `labels.${label.shape}.override.image.width`) || 80}px`,
  };
  const textTops = {
    line1: getValueFromObject(JSONData, `labels.${label.shape}.override.line1.top`) || 105,
    line2: getValueFromObject(JSONData, `labels.${label.shape}.override.line2.top`) || 116,
  };
  const dateText = {
    top: getValueFromObject(JSONData, `labels.${label.shape}.override.date.top`) || 165,
    left: getValueFromObject(JSONData, `labels.${label.shape}.override.date.left`) || 90,
  };
  if (label.text.line2) textTops.line1 = getValueFromObject(JSONData, `labels.${label.shape}.override.line1.hasTwo`) || 94;

  useEffect(() => {
    if (label.default || label.category !== category) {
      if (label.category !== category) {
        saveLabelToLocalstorage({ label }, label.category);
      }
      const localLabel = getLabelFromLocalstorage(category);
      setLabel(
        localLabel
          ? { ...localLabel, default: false }
          : { ...defaultLabel(category), default: false },
      );
    }
  }, []);

  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-full-mobile is-two-fifths-desktop">
          <div className={`${labelMakerStyles.stickyCard} card`}>
            <div className="card-content has-text-centered">
              <p className="has-text-grey-light p-b-sm">
                <span className="icon p-r-sm">
                  <i className="fas fa-eye" />
                </span>
                <FormattedMessage id="label-maker.preview.title" />
              </p>
              <div className={labelMakerStyles.preview}>
                <PreviewShape fill={label.border} />
                <img
                  className="image"
                  style={imageStyle}
                  src={imageSrc}
                  alt={intl.formatMessage({ id: `label-maker.images.titles.${label.image}` })}
                  title={intl.formatMessage({ id: `label-maker.images.titles.${label.image}` })}
                />
                <p
                  className={`${labelMakerStyles.text} ${label.text.font} ${label.text.line2 ? labelMakerStyles.hasTwo : ''}`}
                  style={{
                    color: label.text.color,
                    fontSize: `${label.text.size}px`,
                    top: `${textTops.line1}px`,
                  }}
                >
                  {label.text.line1}
                </p>
                <p
                  className={`${labelMakerStyles.text} ${labelMakerStyles.textTwo} ${label.text.font}`}
                  style={{
                    color: label.text.color,
                    fontSize: `${label.text.size}px`,
                    top: `${textTops.line2}px`,
                  }}
                >
                  {label.text.line2}
                </p>
                <p
                  className={`${labelMakerStyles.date} ${label.text.font}`}
                  style={{
                    color: label.text.color,
                    fontSize: `${label.text.size}px`,
                    top: `${dateText.top}px`,
                    left: `${dateText.left}px`,
                  }}
                >
                  {label.text.date}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-full-mobile is-three-fifths-desktop">
          <div className={`card ${labelMakerStyles.resetOverflow}`}>
            <div className="card-content">
              <ul className="steps is-horizontal is-medium is-centered has-content-centered">
                {tabs}
              </ul>
              <div>
                {children}
                <div className={`level is-mobile ${currentStep.id === 'image' ? `${labelMakerStyles.stickyButtons} m-t-xl` : 'm-t-lg'}`}>
                  <div className="level-left">
                    <button
                      type="button"
                      className={`button is-info is-light is-right ${currentStep.id === JSONData.steps[0].id ? 'is-hidden' : ''}`}
                      onClick={previousStep}
                    >
                      <span className="icon is-small">
                        <i className="fas fa-chevron-left" />
                      </span>
                      <span className="is-hidden-mobile">
                        <FormattedMessage id="label-maker.buttons.previous" />
                      </span>
                      <span className="is-hidden-tablet">
                        <FormattedMessage id="label-maker.buttons.previous-mobile" />
                      </span>
                    </button>
                  </div>
                  <div className="level-right has-text-right">
                    <button
                      type="button"
                      className={`button is-info is-light is-right ${currentStep.id === 'print' ? 'is-hidden' : ''}`}
                      onClick={nextStep}
                    >
                      <span className="is-hidden-mobile">
                        <FormattedMessage id="label-maker.buttons.next" />
                      </span>
                      <span className="is-hidden-tablet">
                        <FormattedMessage id="label-maker.buttons.next-mobile" />
                      </span>
                      <span className="icon is-small">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </button>
                    <Link
                      to={localizedUrl(`${categorySlug}/result`)}
                      className={`button is-info is-light is-right ${currentStep.id !== 'print' ? 'is-hidden' : ''}`}
                    >
                      <span className="is-hidden-mobile">
                        <FormattedMessage id="label-maker.buttons.finalize" />
                      </span>
                      <span className="is-hidden-tablet">
                        <FormattedMessage id="label-maker.buttons.finalize-mobile" />
                      </span>
                      <span className="icon is-small">
                        <i className="fas fa-check" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LabelMaker.propTypes = {
  intl: PropTypes.object,
  label: PropTypes.object,
  setLabel: PropTypes.func,
  children: PropTypes.node,
  category: PropTypes.string,
  categorySlug: PropTypes.string,
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  label: getLabel(state),
});

const mapDispatchToProps = {
  setLabel: setLabelAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LabelMaker));
