import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import JSONData from '../../conf/label-maker.json';
import { setText as setTextAction } from '../../state/actions';
import { getLabelText, getLabelBorder } from '../../state/selectors';
import RadioButton from '../radio-button';

const LabelMakerText = ({
  currentText, currentBorderColor, setText, pageCategory,
}) => {
  function setTexts(field, event) {
    setText({
      [field]: event.target.value,
    });
  }

  function setFont(event) {
    setText({
      font: event.target.value,
    });
  }

  function setColor(event) {
    setText({
      color: event.target.value,
    });
  }

  function decreaseSize() {
    setText({
      size: currentText.size > 10 ? currentText.size - 1 : 10,
    });
  }

  function increaseSize() {
    setText({
      size: currentText.size < 25 ? currentText.size + 1 : 25,
    });
  }

  let dateLabel;
  let helpLabel;
  switch (pageCategory) {
    case 'bottle':
    case 'gift':
    case 'default':
      dateLabel = <FormattedMessage id="label-maker.text.line3" />;
      helpLabel = <FormattedMessage id="label-maker.text.help-line" />;
      break;
    case 'school':
      dateLabel = <FormattedMessage id="label-maker.text.year" />;
      helpLabel = <FormattedMessage id="label-maker.text.help-year" />;
      break;
    default:
      dateLabel = <FormattedMessage id="label-maker.text.date" />;
      helpLabel = <FormattedMessage id="label-maker.text.help" />;
      break;
  }

  const fontOptions = [];
  JSONData.fonts.map((font) => fontOptions.push(
    <div className="column is-half p-xs" key={font}>
      <label className="radio" htmlFor={font}>
        <input id={font} type="radio" name="font" value={font} checked={currentText.font === font} onChange={() => {}} />
        <span className={`${font} p-l-sm is-size-4`}>
          <FormattedMessage id={`label-maker.text.fonts.${font}`} />
        </span>
      </label>
    </div>,
  ));

  const borderColor = currentBorderColor === 'black' ? '#888888' : currentBorderColor;

  return (
    <form>
      <div className="notification is-info is-light has-icon">
        <span className="icon">
          <i className="fas fa-info-circle fa-lg" />
        </span>
        <span className="p-l-lg">
          {helpLabel}
        </span>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label" htmlFor="line1">
            <FormattedMessage id="label-maker.text.line1" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control" style={{ maxWidth: '360px' }}>
              <input className="input" type="text" id="line1" name="line1" value={currentText.line1} onChange={setTexts.bind(this, 'line1')} />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label" htmlFor="line2">
            <FormattedMessage id="label-maker.text.line2" />
          </label>
        </div>
        <div className="field-body">
          <div className="field is-half">
            <div className="control" style={{ maxWidth: '360px' }}>
              <input className="input" type="text" id="line2" name="line2" value={currentText.line2} onChange={setTexts.bind(this, 'line2')} />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label" htmlFor="date">
            {dateLabel}
          </label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <input className="input" type="text" id="date" name="date" value={currentText.date} onChange={setTexts.bind(this, 'date')} />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal" style={{ paddingTop: '1.5em' }}>
          <label className="label" htmlFor="font-color">
            <FormattedMessage id="label-maker.text.color" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control" onChange={setColor.bind(this)}>
              <RadioButton name="font-color" className="m-smd" value="black" checked={currentText.color === 'black'} key="black" color="black" />
              <RadioButton name="font-color" className="m-smd" value={borderColor} checked={currentText.color === borderColor} key={borderColor} color={borderColor} />
              <label className="m-t-xs m-l-sm button is-info is-light button-color" htmlFor="custom-color">
                <input type="color" name="custom-color" id="custom-color" defaultValue="#ffffff" />
                <span className="p-l-sm">
                  <FormattedMessage id="label-maker.border.color-picker" />
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal m-t-md">
        <div className="field-label is-normal ">
          <label className="label" htmlFor="font">
            <FormattedMessage id="label-maker.text.font" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="columns is-multiline is-1 control" onChange={setFont.bind(this)}>
              {fontOptions}
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal m-t-lg">
        <div className="field-label is-normal">
          <label className="label" htmlFor="font-size">
            <FormattedMessage id="label-maker.text.size" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <button type="button" className="button is-info is-light vmiddle" onClick={decreaseSize}>
                <span className="icon">
                  <i className="fas fa-minus" />
                </span>
              </button>
              <span className="is-size-6 p-l-md p-r-md p-t-sm vmiddle">{currentText.size}</span>
              <button type="button" className="button is-info is-light vmiddle" onClick={increaseSize}>
                <span className="icon">
                  <i className="fas fa-plus" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="has-text-grey-light text-smaller">
        <FormattedMessage id="label-maker.text.help2" />
      </p>
    </form>
  );
};

LabelMakerText.propTypes = {
  currentText: PropTypes.object,
  currentBorderColor: PropTypes.string,
  setText: PropTypes.func,
  pageCategory: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentText: getLabelText(state),
  currentBorderColor: getLabelBorder(state),
});

const mapDispatchToProps = {
  setText: setTextAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LabelMakerText));
